import * as React from "react"
import Layout from "../components/common/layout"
import {  graphql } from "gatsby"
import { Helmet } from 'react-helmet'
import ListVideo from "../components/list-videos"
// import Hero from "../components/hero"
import Heading from "../components/common/heading"
import PreFooter from "../components/pre-footer"
import PartnerBanner from "../components/partner-banner"
import SubMenu from "../components/common/sub-menu"
// markup 
const VideoHubCategory = ({ data }: any) => {
  const _data = data.allSampleDataJson?.nodes[0].data || []
  const videos = _data.find((item: any) => item.block === 'videos')
  const subMenu = _data.find((item: any) => item.block === 'sub-menu')
  // const hero = _data.find((item: any) => item.block === 'hero')
  const dataPrefooter = _data.find((item: any) => item.block === 'prefooter')
  const dataPartnerBanner = _data.find((item: any) => item.block === 'partnerBanner')
  return (
    <Layout>
      <Helmet
        title='Video main category'
      />
      <SubMenu data={subMenu.data[0]} />
      {/* <Hero background="bg-blue-white" data={hero.data[0]} container={true} noSlider={true}></Hero> */}
    
      <Heading 
        title='Latest videos' 
        background='bg-white'  
        />
      <ListVideo data={videos.data[0]} />
      <Heading 
        title='Popular videos' 
        background='bg-white'  
        />
       < ListVideo data={videos.data[1]} />
      <PartnerBanner data={dataPartnerBanner.data[0]} background="bg-green" ></PartnerBanner>
      <PreFooter data={dataPrefooter.data[0]} background="bg-blue"></PreFooter>  
    </Layout>
  )
}

// Step 2: Export a page query
export const query = graphql`
  query {
    allSampleDataJson(filter: {jsonName: {in: ["video-main-category"]}}) {
      nodes {
        jsonName
        data {
          block
          data {
            hero {
              hight_light
              content
              images {
                id
                url
                width
              }
              title
              title_top
            }
           
            partner_banner {
              content
              link {
                id
                text
                url
              }
              logo
              partner_image
            }
            prefooter_data {
              title
              content
              links {
                id
                text
                url
              }
            } 
            sub_menu{
              title
              links {
                id
                text
                link
                active
              }
            }
            videos {
              id
              title
              link
              image
              video {
                length
                level
                name
                tag
              }
            }
          }
        }
      }
    }
  }
`
export default VideoHubCategory
